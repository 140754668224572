import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import Image from 'next/legacy/image';
import Link from 'next/link';
import type { FC } from 'react';
import React, { useCallback } from 'react';

import AddButton from '@/components/common/AddButton/AddButton';
import { LikeIcon } from '@/components/common/LikeIcon/LikeIcon';
import { MacrosContainer } from '@/components/common/MacrosContainer/MacrosContainer';
import { PackAndDiscount } from '@/components/common/PackAndDiscount/PackAndDiscount';
import { PrimaryDisplayTag } from '@/components/common/PrimaryDisplayTag/PrimaryDisplayTag';
import QuantityButton from '@/components/common/QuantityButtonV2/QuantityButtonV2';
import { SecondaryTag } from '@/components/common/SecondaryTag/SecondaryTag';
import { TertiaryTag } from '@/components/common/TertiaryTag/TertiaryTag';
import SwapButton from '@/components/menu/SwapButton/SwapButton';
import type { ProductModelType } from '@/models/api';
import type { OrderPlan, Position } from '@/models/cart/order-plan';
import { useStore } from '@/models/root-store';
import { Action, Mode } from '@/utils/constants';
import { trackProductClicked } from '@/utils/track/tracker.helper';

import ProductLikePill from '../ProductLikePill/ProductLikePill';

export type MBCardType = {
  key: string | undefined;
  product: ProductModelType;
  orderPlan: OrderPlan;
  discount?: number;
  action?: Action;
  handleSwap: (
    product: ProductModelType,
    x: number,
    y: number,
    location?: string,
  ) => void;
  selectedItemPosition?: Position;
  planRoute: string;
  mode?: Mode;
  planIdToView?: string;
  filterNavHeight?: number;
};
const MenuBuilderCard: FC<MBCardType> = observer(
  ({
    product,
    orderPlan,
    action,
    handleSwap,
    selectedItemPosition,
    planRoute,
    mode,
    planIdToView,
    filterNavHeight = 0,
  }: MBCardType) => {
    const { cartStore } = useStore();
    const { cms, sku, slug, promotionTotal, grossPrice } = product;
    const { cart } = cartStore;
    const { isCartOpen, closeCart } = cart;

    const primaryDisplayTag = cms?.primaryDisplayTag;
    const secondaryDisplayTag = cms ? cms.secondaryDisplayTag : null;
    const tertiaryDisplayTag = cms ? cms.tertiaryDisplayTag : null;

    const productImage = cms ? cms.tileImage?.url : '';

    const quantity = orderPlan?.productQtyBySku[product.sku]?.qty || 0;

    const handleAddProduct = useCallback(() => {
      orderPlan?.increaseLineItem(product, 1, 'menu_builder');
    }, [orderPlan]);

    const handleDeleteProduct = useCallback(() => {
      orderPlan?.decreaseLineItem(product, 'menu_builder');
    }, [orderPlan]);

    const handleSwapItem = () => {
      const { x, y } = selectedItemPosition as Position;
      handleSwap(product, x, y, 'Goal Based');
    };

    const handleImageClick = () => {
      try {
        trackProductClicked(product, 'Menu Builder');
      } catch (error) {
        // ignore error
      }
      orderPlan.setScrollPosition(window.scrollY);
    };

    const renderButtons = () => {
      switch (action) {
        case Action.SWAP_ITEM:
          return (
            <Link
              href={{
                pathname: planRoute,
                query: {
                  ...(mode && { mode }),
                  planId: planIdToView,
                },
              }}
              legacyBehavior
            >
              <div className="flex justify-end">
                <SwapButton onClick={() => handleSwapItem()} />
              </div>
            </Link>
          );
        case Action.ADD_ITEM:
        case Action.ADD_EXTRA:
          return (
            <Link
              href={{
                pathname: planRoute,
                query: {
                  ...(mode && { mode }),
                  planId: planIdToView,
                },
              }}
              legacyBehavior
            >
              <div className="flex justify-end">
                <AddButton
                  onClick={() => handleSwapItem()}
                  text={'Add to plan'}
                />
              </div>
            </Link>
          );
        default:
          if (quantity > 0) {
            return (
              <QuantityButton
                handleAddProduct={handleAddProduct}
                handleDeleteProduct={handleDeleteProduct}
                qty={quantity}
                size="medium"
              />
            );
          }
          return (
            <div className="flex justify-end">
              <AddButton
                onClick={handleAddProduct}
                text={
                  mode === Mode.EDIT_SUBSCRIPTION
                    ? 'Add to order'
                    : 'Add to cart'
                }
              />
            </div>
          );
      }
    };

    const packs =
      product.hasSubProducts && product.subProducts
        ? _.sumBy(product.subProducts, (sub) => sub.qty)
        : 0;

    const discountable =
      (product.isBulkDiscountable && orderPlan?.discount) ||
      product.hasSubProducts ||
      promotionTotal;

    return (
      <div
        id={sku}
        className={`relative rounded-2xl transition-colors`}
        style={{ scrollMargin: filterNavHeight + 10 }}
      >
        <div>
          {/* Tags */}
          {primaryDisplayTag && <PrimaryDisplayTag tag={primaryDisplayTag} />}
          {productImage && (
            <>
              <LikeIcon product={product} />
              <Link
                href={{
                  pathname: `/products/${slug}`,
                  query: {
                    ...(mode && { mode }),
                    ...(action && { action }),
                  },
                }}
                legacyBehavior
              >
                <div
                  className="relative"
                  onClick={() => {
                    if (isCartOpen) {
                      closeCart();
                    }
                  }}
                >
                  <Image
                    className={
                      'w-full cursor-pointer rounded-t-2xl object-fill'
                    }
                    width={150}
                    height={150}
                    src={productImage}
                    alt={''}
                    onClick={() => handleImageClick()}
                    quality={100}
                    layout="responsive"
                  />

                  {tertiaryDisplayTag && (
                    <TertiaryTag
                      tag={tertiaryDisplayTag}
                      fullWidth={!secondaryDisplayTag}
                    />
                  )}
                  {secondaryDisplayTag && (
                    <SecondaryTag
                      tag={secondaryDisplayTag}
                      fullWidth={!tertiaryDisplayTag}
                    />
                  )}
                </div>
              </Link>
            </>
          )}
          {/* Nutrition Info */}
          {!product.hasSubProducts && (
            <MacrosContainer nutritionalInfo={product.nutritionalInfo} />
          )}
          {/* Packs and Discount for bundles */}
          {product.hasSubProducts && promotionTotal && grossPrice && (
            <PackAndDiscount
              promotionTotal={promotionTotal}
              grossPrice={grossPrice}
              packs={packs}
              menu={true}
              quantityTag={cms?.quantityTag}
            />
          )}
          <div className={'bg-off-white p-2 pb-3 md:p-4'}>
            <Link
              href={{
                pathname: `/products/${slug}`,
                query: {
                  ...(mode && { mode }),
                  ...(action && { action }),
                },
              }}
              legacyBehavior
            >
              <span
                className={
                  'mb-1 line-clamp-2 min-h-[36px] font-interMedium text-sm leading-[18px] hover:cursor-pointer lg:min-h-[48px] lg:text-base lg:leading-6'
                }
              >
                {product.cms?.primaryName || product.name}
              </span>
            </Link>

            {/* Price */}
            <div className={'mb-1 flex justify-between md:mb-3'}>
              <div
                className={
                  'flex items-end py-1 font-interSemibold text-base text-black md:text-base'
                }
              >
                <span
                  className={`leading-5 ${
                    discountable ? 'text-success-secondary' : ''
                  }`}
                >
                  ${product.getDiscountedPrice(orderPlan?.discount).toFixed(2)}
                </span>
                {/* Original price for bundles */}
                {discountable ? (
                  <span
                    className={
                      'px-2 text-xs leading-4 text-grey-dark line-through lg:text-sm'
                    }
                  >
                    ${product?.grossPrice?.toFixed(2)}
                  </span>
                ) : null}
              </div>

              {/* Desktop Weight */}
              {!product.hasSubProducts && (
                <div className="flex items-center py-1 font-interMedium text-xs text-grey-dark">
                  {product.nutritionalInfo.size}
                  {product.nutritionalInfo.unit}
                </div>
              )}
            </div>

            <div className="flex">
              <ProductLikePill
                view="small"
                sku={product.sku}
                selected={
                  mode === Mode.EDIT_SUBSCRIPTION ? false : quantity > 0
                }
              />
            </div>
          </div>
          {/* Add buttons for different modes */}
          {renderButtons()}
        </div>
      </div>
    );
  },
);

export default MenuBuilderCard;
